


























import { Component } from "vue-property-decorator";
import { Routes } from "@/types/core";
import { BaseView } from "@/core/UI/Views/BaseView";
import { mixins } from "vue-class-component";
import userRolesMixin from "@/mixins/userRoles";

enum Tabs {
  CYCLE,
  EVENT,
}

import EventsList from "@/components/widgets/events/EventsList.vue";
import CyclesList from "@/components/widgets/cycles/CyclesList.vue";
import {
  CycleEventsQueryVariables,
  InputCycleEventsEnum,
  EventsQueryVariables,
  InputEventsEnum,
  SqlOperator,
} from "@/generated/graphql";

@Component({
  name: "ArchivePage",
  components: {
    EventsList,
    CyclesList,
  },
})
export default class extends mixins(userRolesMixin, BaseView) {
  protected Routes = Routes;
  protected tab = Tabs.CYCLE;
  private readonly Tabs = Tabs;

  public created(): void {
    if (!this.VIEW_ARCHIVE_LIST) this.$router.push({ name: Routes.noRights });
  }

  protected get eventsListExtraParams(): Partial<EventsQueryVariables> {
    return {
      where: {
        column: InputEventsEnum.IsArchive,
        operator: SqlOperator.Eq,
        value: true,
      },
    };
  }
  protected get eventsListExtraParamsCycle(): Partial<CycleEventsQueryVariables> {
    return {
      where: {
        column: InputCycleEventsEnum.IsArchive,
        operator: SqlOperator.Eq,
        value: true,
      },
    };
  }
}
